<template>
    <div class="wallet-container">
        <div class="top"></div>
        <div
            style="width: 100vw;height: 100vh;position: absolute;display: flex;flex-direction: column;align-items: center;">
            <Header :title="'我的钱包'" :bgColor="'#00000000'" :leftArrowType="1" :titleColor="'#ffffff'" :leftClick="goBack">
            </Header>
            <div style="height: calc(100vh - 132px);padding: 15px;width: 100%;box-sizing: border-box;">
                <div style="text-align: center;height: 100px;">
                    <p style="color: white;font-size: 36px;;">{{ userKenengPointsBalance }}</p>
                    <p style="color: white;font-size: 14px;margin-top: 10px;margin-bottom: 15px;">可能点余额</p>
                </div>
                <div
                    style="box-sizing: border-box;width: 100%;border-radius: 12px;height: 100%;background-color: white;height: calc(100vh - 232px);">
                    <p style="width: 100%;padding: 15px 0px;text-align: center;color: #262c3a;font-size: 18px;">
                        充值明细</p>
                    <div style="width: 100%;height: 1px;background-color:#F2F2F7;"></div>
                    <div style=";overflow-y: auto;height: calc(100vh - 293px);">
                        <div v-for="(order, index) in orderList" :key="index" class="order-item">
                            <div style="display: flex;">
                                <p class="status" :style="{ color: order.benefits_added == true ? '#52c41a' : '#cc0000' }">
                                    {{
                                        order.benefits_added == true ? '成功' : '失败' }}</p>
                                <p class="product_name">{{ order.price_info.product_name }}</p>
                                <p class="product-price">{{ order.price_info.product_price && order.price_info.product_price
                                    != '0' ?
                                    '-' + order.price_info.product_price + '元' : '免费' }}</p>
                            </div>
                            <div style="display: flex;margin-top: 10px;">
                                <p class="order-time">{{ formatOrderTime(order.create_time) }}</p>
                                <p class="order-points">{{ '+' + order.price_info.product_points + '可能点' }}</p>
                            </div>
                        </div>
                    </div>
                    <div style="width: 100%;height: 30px;background-color:transparent;"></div>
                </div>
            </div>
            <div style="width: 100%;padding: 15px;box-sizing: border-box;">
                <p class="btn-purchase" @click="gotoPurchase">立即充值</p>
            </div>
        </div>
    </div>
</template>
<script>
import {
    getAppUserId
} from '../../utils/store'
import {
    getUserKenengPointsBalance, getMyOrders
} from '../../api/app'
import {
    formatDate
} from '../../utils/date'
import { Loading } from "element-ui";
import Header from "../../components/app/Header.vue"
export default ({
    name: '',
    components: {
        Header,
    },
    data() {
        return {
            userKenengPointsBalance: '',
            orderList: []
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        async fetchData() {
            let loadingInstance = Loading.service({
                fullscreen: true,
                text: "加载中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.8)",
            });
            let balanceResult = await getUserKenengPointsBalance()
            this.userKenengPointsBalance = balanceResult.data.data
            let orderListResult = await getMyOrders()
            this.orderList = orderListResult.data.data
            loadingInstance.close()
        },
        goBack() {
            this.$router.go(-1)
        },
        formatOrderTime(time) {
            return formatDate(new Date(time * 1000), 'Y年MM月dd日 hh时mm分ss秒')
        },

        gotoPurchase() {
            this.$router.push({
                path: '/app-products'
            })
        },
    }
})
</script>
<style lang="less" scoped>
.order-item {
    width: 100%;
    padding: 15px 15px;
    box-sizing: border-box;
    border-bottom: 1px solid #F2F2F7;
    margin-bottom: 15px;

    .order-points {
        color: #9698a2;
        font-size: 14px;
    }

    .order-time {
        flex: 1;
        padding-right: 15px;
        box-sizing: border-box;
        width: 100%;
        color: #9698a2;
        font-size: 14px;
    }

    .status {
        color: #52c41a;
        font-size: 16px;
    }

    .product_name {
        flex: 1;
        width: 100%;
        padding: 0px 10px;
        color: #262c3a;
        font-size: 16px;
    }

    .product-price {
        color: #262c3a;
        font-size: 16px;
    }

}

.btn-purchase {
    cursor: pointer;
    box-sizing: border-box;
    padding: 15px 0px;
    box-sizing: border-box;
    color: white;
    font-size: 16px;
    text-align: center;
    border-radius: 34px;
    background-color: #5d5bb5;
}

.wallet-container {
    width: 100vw;
    height: 100vh;
    position: relative;
    background-color: #f5f5f5;

    .top {
        position: absolute;
        width: 100%;
        height: 280px;
        background-image: url('../../assets/app/bg_keneng_point.png');
        background-repeat: no-repeat;
        background-size: cover;
    }
}
</style>
